import { getVariantConfig } from '@ext/ndsbase/Resources/Public/Assets/Js/Utilities/variant-utility.js'

let createResultListApp = null

const appContainers = Array.prototype.slice.call(
  document.querySelectorAll('.result-list-app')
)

const getRootPropsFromDataset = container => {
  let rootProps = {}
  for (let [key, value] of Object.entries(container.dataset)) {
    let parsedValue = value;

    if (key.indexOf('json') === 0) {
      parsedValue = JSON.parse(value)
      key = key.replace(/^json/, '')
      key = key.charAt(0).toLowerCase() + key.slice(1)
    }
    rootProps[key] = parsedValue
  }

  return rootProps
}

const initResultListApp = async (container) => {
  // preload the variant configuration
  await getVariantConfig()
  if (null === createResultListApp) {
    createResultListApp = await import('@ext/ndssearch/Resources/Public/Assets/Js/Components/ResultList/result-list-app.js')
  }
  let rootProps = getRootPropsFromDataset(container)
  createResultListApp.default(container, rootProps)
}

let observer = new IntersectionObserver(entries => {
  for (const entry of entries) {
    if (entry.intersectionRatio > 0) {
      observer.unobserve(entry.target)
      initResultListApp(entry.target).then(() => console.debug('App loaded'))
    }
  }
})

for (const appContainer of appContainers) {
  observer.observe(appContainer)
}
