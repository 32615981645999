let variantConfig
import {getProperty} from 'dot-prop'

export const getVariantConfig = async () => {
  if (undefined === variantConfig) {
    try {
      const searchParams = new URLSearchParams(window.location.search || '')
      for (const [key, value] of searchParams) {
        if (!key.startsWith('prev-component')) {
          searchParams.delete(key)
        }
      }
      searchParams.set('fe-resource', 'variant-config')
      const response = await fetch('?' + searchParams.toString())
      variantConfig = (await response.json()) || {}
    } catch (e) {
      console.error(e)
      variantConfig = {}
    }
  }

  return variantConfig
}

export const getVariantByObjectPath = async objectPath => {
  const variantConfig = await getVariantConfig()
  return getProperty(variantConfig, objectPath)
}

export const importVariantByObjectPath = async (objectPath, variantList, defaultName = 'Variant0') => {
  let variant = (await getVariantByObjectPath(objectPath)) || defaultName
  // fallback to defaultName if the variant doesn't exist in the variant list
  variant = variantList.has(variant) ? variant : defaultName

  return variantList.get(variant)()
}
